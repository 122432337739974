import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import Box from '@mui/material/Box';

import { useStore } from '../../stores/Store';
import LeftDrawer from './LeftDrawer';
import TopNavBar from './TopNavBar';

const AppNav = () => {
  const { navStore } = useStore();
  const { toggleDrawer, loadActiveMenu } = navStore;

  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    loadActiveMenu(false);
  }, [loadActiveMenu]);

  return (
    <Box sx={{ display: 'flex' }}>
      <TopNavBar onMenuClick={toggleDrawer} />
      <LeftDrawer />
    </Box>
  );
};

export default observer(AppNav);
