import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import MenuItemData from '@ashiteam/base-react-lib/dist/models/MenuItemData/MenuItemData';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import GlobalHelper from '../../lib/GlobalHelper';
import { useStore } from '../../stores/Store';
import NavButton from './NavButton';

interface Props {
  onMenuClick: () => void;
}

const TopNavBar = ({ onMenuClick }: Props) => {
  const { navStore } = useStore();
  const { getTopBarMenuItems } = navStore;

  const getNavItem = (item: MenuItemData) => {
    if (item.isDevider !== undefined && item.isDevider) {
      return <Box key={item.key} sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} />;
    } else {
      return (
        <NavButton
          key={item.key}
          to={item.route}
          buttonText={item.title}
          buttonProps={{ variant: 'text', sx: { color: '#fff' } }}
          startIcon={item.startIcon}
          endIcon={item.endIcon}
          subMenus={item.subMenus}
        />
      );
    }
  };

  return (
    <AppBar component='nav'>
      <Container maxWidth={GlobalHelper.containerMaxWidth}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={onMenuClick}
            sx={{ mr: 1, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <NavLink to='/' style={{ marginTop: '6px' }}>
            {/* <img
              src='/favicon-32x32.png'
              alt='Album App Logo'
              style={{ width: '40px', height: '40px', borderRadius: '8px' }}
            ></img> */}
            <img
              height='40px'
              width='40px'
              src='images/logo/40/logo40.png'
              alt='logo40'
              srcSet='images/logo/40/logo40.webp 1x, images/logo/40/logo40.png 1x, images/logo/40/logo40-1.5x.webp 1.5x, images/logo/40/logo40-1.5x.png 1.5x, images/logo/40/logo40-2x.webp 2x, images/logo/40/logo40-2x.png 2x, images/logo/40/logo40-2.4x.webp 2.4x, images/logo/40/logo40-2.4x.png 2.4x, images/logo/40/logo40-2.5x.webp 2.5x, images/logo/40/logo40-2.5x.png 2.5x, images/logo/40/logo40-3x.webp 3x, images/logo/40/logo40-3x.png 3x'
              style={{ borderRadius: '8px' }}
            />
          </NavLink>
          {getTopBarMenuItems().map((item) => getNavItem(item))}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default observer(TopNavBar);
