import { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import MenuItemData from '@ashiteam/base-react-lib/dist/models/MenuItemData/MenuItemData';
import MenuRoute from '@ashiteam/base-react-lib/dist/models/MenuItemData/MenuRoute';
import Button, { ButtonTypeMap } from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

interface Props {
  buttonText: string;
  to: MenuRoute;
  buttonProps?: DefaultComponentProps<ButtonTypeMap>;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  subMenus?: MenuItemData[];
}

const NavButton = ({ to, buttonText, buttonProps, startIcon, endIcon, subMenus }: Props) => {
  const location = useLocation();
  const active = location.pathname === to;
  const fontWeight = active ? 800 : 400;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!!subMenus) {
      setAnchorEl(event.currentTarget);
    } else if (typeof to !== 'string') {
      to();
    }
  };

  const handleClose = (route?: MenuRoute) => {
    setAnchorEl(null);
    if (!!route) {
      if (typeof route == 'string') {
        navigate(route);
      } else {
        route();
      }
    }
  };

  const getMenuItem = (menu: MenuItemData) => {
    if (menu.isDevider) {
      return <Divider key={menu.key} />;
    } else {
      return (
        <MenuItem key={menu.key} onClick={() => handleClose(menu.route)}>
          {menu.title}
        </MenuItem>
      );
    }
  };

  const getButton = () => {
    return (
      <>
        <Button
          {...buttonProps}
          onClick={handleClick}
          style={{ fontWeight: fontWeight }}
          startIcon={startIcon}
          endIcon={endIcon}
        >
          {buttonText}
        </Button>
        {!!subMenus && subMenus.length > 0 && (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={() => handleClose()}
          >
            {subMenus.map((menu) => getMenuItem(menu))}
          </Menu>
        )}
      </>
    );
  };

  const isRoute = () => {
    return typeof to === 'string' && !subMenus;
  };

  if (isRoute()) {
    return (
      <NavLink to={to as string} style={{ textDecoration: 'none' }}>
        {getButton()}
      </NavLink>
    );
  } else {
    return getButton();
  }
};

export default NavButton;
