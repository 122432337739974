import React from 'react';

import SignIn from '@ashiteam/mui-react-lib/dist/components/SignIn/SignIn';
import Box from '@mui/material/Box';

import { useStore } from '../stores/Store';

const SignInPage = () => {
  const { userStore } = useStore();
  const { signIn } = userStore;

  return (
    <Box sx={{ pt: 6 }}>
      <SignIn copyright={{ text: 'Ashi Albums', url: 'https://ashiteam.com' }} onLogin={signIn} />
    </Box>
  );
};

export default SignInPage;
