import { createTheme, ThemeOptions } from '@mui/material/styles';

export const lightThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#e95420',
    },
    secondary: {
      main: '#aea79f',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    error: {
      main: '#df382c',
    },
    warning: {
      main: '#efb73e',
    },
    info: {
      main: '#17a2b8',
    },
    success: {
      main: '#38b44a',
    },
  },
};

export const darkThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#772953',
    },
    secondary: {
      main: '#aea79f',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    error: {
      main: '#df382c',
    },
    warning: {
      main: '#efb73e',
    },
    info: {
      main: '#17a2b8',
    },
    success: {
      main: '#38b44a',
    },
  },
};

export const lightTheme = createTheme(lightThemeOptions);
export const darkTheme = createTheme(darkThemeOptions);
