import { createContext, useContext } from 'react';

import NavStore from './NavStore';
import UserStore from './UserStore';

interface Store {
  navStore: NavStore;
  userStore: UserStore;
}

export const store: Store = {
  navStore: new NavStore(),
  userStore: new UserStore(),
};

export const StoreContext = createContext(store);

export const useStore = () => {
  return useContext(StoreContext);
};
