import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const UnderConstruction = () => {
  return (
    <Paper elevation={8} sx={{ mt: 10, p: 3, pb: 5, textAlign: 'center' }}>
      <Typography variant='h6'>This page is still under construction.</Typography>
      <Typography variant='body1'>Please visit again later.</Typography>
      <br />
      {/* <img
        width='100px'
        height='100px'
        src='/underconstruction.png'
        alt='Under Construction'
        style={{ maxWidth: '100px' }}
      /> */}
      <img
        height='100px'
        width='100px'
        src='images/underconstruction/100/underconstruction.png'
        alt='underconstruction'
        srcSet='images/underconstruction/100/underconstruction.webp 1x, images/underconstruction/100/underconstruction.png 1x, images/underconstruction/100/underconstruction-1.5x.webp 1.5x, images/underconstruction/100/underconstruction-1.5x.png 1.5x, images/underconstruction/100/underconstruction-2x.webp 2x, images/underconstruction/100/underconstruction-2x.png 2x, images/underconstruction/100/underconstruction-2.4x.webp 2.4x, images/underconstruction/100/underconstruction-2.4x.png 2.4x, images/underconstruction/100/underconstruction-2.5x.webp 2.5x, images/underconstruction/100/underconstruction-2.5x.png 2.5x, images/underconstruction/100/underconstruction-3x.webp 3x, images/underconstruction/100/underconstruction-3x.png 3x'
      />
    </Paper>
  );
};

export default UnderConstruction;
