import MenuItemData from '@ashiteam/base-react-lib/dist/models/MenuItemData/MenuItemData';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Breakpoint } from '@mui/material/styles';

import { store } from '../stores/Store';

export default class GlobalHelper {
  static get containerMaxWidth(): Breakpoint {
    return 'lg';
  }

  static get userNameMenuTag() {
    return '[USER_NAME]';
  }

  static get menu(): MenuItemData[] {
    return [
      {
        key: 1,
        title: 'Albums',
        route: '/',
        showWhen: 'always',
        alwaysInTopBar: true,
      },
      {
        key: 2,
        title: 'Events',
        route: '/events',
        showWhen: 'always',
      },
      {
        key: 3,
        title: 'Categories',
        route: '/categories',
        showWhen: 'always',
      },
      {
        key: 4,
        title: 'Locations',
        route: '/locations',
        showWhen: 'always',
      },
      {
        key: 5,
        title: 'Members',
        route: '/members',
        showWhen: 'isAdmin',
      },
      {
        key: 6,
        title: 'Admin',
        route: '/admin',
        showWhen: 'isAdmin',
      },
      {
        key: 7,
        title: '',
        route: '',
        isDevider: true,
        showWhen: 'always',
      },
      {
        key: 9,
        title: 'Signin',
        route: '/signin',
        showWhen: 'loggedOut',
      },
      {
        key: 8,
        // title: 'Welcome ' + GlobalHelper.userNameMenuTag,
        title: GlobalHelper.userNameMenuTag,
        route: '',
        showWhen: 'loggedIn',
        endIcon: <AccountCircle />,
        subMenus: [
          {
            key: 81,
            title: 'Edit Profile',
            route: '/member/edit',
            showWhen: 'loggedIn',
          },
          {
            key: 82,
            title: 'Preferences',
            route: '/member/editpreferences',
            showWhen: 'loggedIn',
          },
          {
            key: 83,
            title: '',
            route: '',
            isDevider: true,
            showWhen: 'loggedIn',
          },
          {
            key: 84,
            title: 'Signout',
            route: () => {
              console.log('Signout');
              store.userStore.signOut();
            },
            showWhen: 'loggedIn',
          },
        ],
      },
    ];
  }

  static getMainApiUrl = () => {
    return 'https://album.weerapura.com/api';
  };
}
