import { observer } from 'mobx-react-lite';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import { useStore } from '../../stores/Store';

const drawerWidth = 200;

const LeftDrawer = () => {
  const { navStore } = useStore();
  const { drawerOpen, setDrawerOpen } = navStore;

  return (
    <Box component='nav'>
      <Drawer
        // container={container}
        variant='temporary'
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      ></Drawer>
    </Box>
  );
};

export default observer(LeftDrawer);
