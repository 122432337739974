import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { User, UserForLogin } from '@ashiteam/base-react-lib/dist/models';

import GlobalHelper from '../lib/GlobalHelper';
import { store } from '../stores/Store';

axios.defaults.baseURL = GlobalHelper.getMainApiUrl(); // process.env.REACT_APP_API_URL;

const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

axios.interceptors.request.use((config) => {
  // console.log(axios.defaults.baseURL);
  const token = store.userStore.token;
  if (token) {
    const json = parseJwt(token);
    const exp = json.exp;
    if (Date.now() < exp * 1000) {
      if (config.headers) {
        (config.headers as any).Authorization = `Bearer ${token}`;
      }
    } else {
      console.log('Token has expired.');
    }
  }
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    const pagination = response.headers['pagination'];
    if (pagination) {
      // response.data = new PaginatedResult(response.data, JSON.parse(pagination));
      // return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError<any, any>) => {
    console.log(error);
    const { data, status, config } = error.response!;
    switch (status) {
      case 400:
        if (typeof data === 'string') {
          toast.error(data);
        }
        if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
          // navigate('/not-found');
          toast.error('Item not found on the server.');
        }
        if (data.errors) {
          const modalStateErrors: any[] = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        }
        break;
      case 401:
        console.log('came to Unauthorized');
        toast.error('You do not have access.');
        break;
      case 404:
        toast.error('Item not found on the server.');
        // navigate('/not-found');
        break;
      case 500:
        // toast.error('Server Error. ' + store.userStore.setServerError.name);
        // store.commonStore.setServerError(data);
        break;
    }
    throw error;
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response?.data;

const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  postWithConfig: <T>(url: string, body: {}, config: {}) => axios.post<T>(url, body, config).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Account = {
  current: () => request.get<User>('/auth'),
  login: (user: UserForLogin) => request.post<User>('/auth/signin', user),
};

const agent = {
  Account,
};

export default agent;
