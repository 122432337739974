import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import NavButton from '../components/nav/NavButton';

const NotFoundPage = () => {
  const theme = createTheme({ palette: { mode: 'dark' } });

  return (
    <ThemeProvider theme={theme}>
      <br />
      <Paper elevation={8} sx={{ mt: 8, p: 3, pb: 5, textAlign: 'center' }}>
        <SearchIcon sx={{ mt: 1, fontSize: '50pt' }} />
        <br />
        <Typography variant='h6'>Oops - we've looked everywhere and could not find this.</Typography>
        <br />
        {/* <img src='/sadface.gif' alt='Sad Face' style={{ maxWidth: '100px' }} /> */}
        <video autoPlay loop muted playsInline>
          <source src='/sadface.webm' type='video/webm' />
          <source src='/sadface.mp4' type='video/mp4' />
        </video>
        <Box sx={{ mt: 3 }}>
          <NavButton to='/' buttonText='Return Home Page' buttonProps={{ variant: 'contained' }} />
        </Box>
      </Paper>
    </ThemeProvider>
  );
};

export default NotFoundPage;
