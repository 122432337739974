import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import User from '@ashiteam/base-react-lib/dist/models/User/User';
import UserForLogin from '@ashiteam/base-react-lib/dist/models/User/UserForLogin';

import agent from '../api/agent';
import { store } from './Store';

export default class UserStore {
  user?: User = undefined;
  // user?: User = {
  //   email: 'asanga@weerapura.com',
  //   knownAs: 'Asanga',
  //   token: 'string',
  //   userName: 'asanga@weerapura.com',
  // };
  token: string | null = window.localStorage.getItem('jwt');

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.token,
      (token: string | null) => {
        if (token) {
          window.localStorage.setItem('jwt', token);
        } else {
          window.localStorage.removeItem('jwt');
        }
      }
    );
  }

  signIn = async (userForLogin: UserForLogin, navigate?: NavigateFunction): Promise<boolean> => {
    try {
      console.log('calling login on agent');
      const user = await agent.Account.login(userForLogin);
      if (!user) {
        console.log('user was null');
        return false;
      }
      console.log(user);
      runInAction(() => {
        this.user = user;
      });
      this.setToken(user.token);
      if (navigate) {
        navigate('/');
      }
      return true;
    } catch (error: any) {
      // throw error;
      console.log('login error');
      console.log(error);
      return false;
    }
  };

  signOut = () => {
    this.setToken(null);
  };

  setToken = (token: string | null) => {
    this.token = token;
    if (!this.token) {
      this.user = undefined;
    }
    store.navStore.loadActiveMenu(true);
  };

  isLoggedIn = () => {
    return !!this.user && !!this.token;
  };

  isAdmin = () => {
    return this.isLoggedIn() && this.user?.email === 'asanga@weerapura.com';
  };
}
