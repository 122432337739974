import './App.css';

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import AppNav from './components/nav/AppNav';
import GlobalHelper from './lib/GlobalHelper';
import AlbumsPage from './pages/AlbumsPage';
import NotFoundPage from './pages/NotFoundPage';
import SignInPage from './pages/SignInPage';

function App() {
  return (
    <div className='App'>
      <CssBaseline />
      <AppNav />
      <Container maxWidth={GlobalHelper.containerMaxWidth}>
        {/* <header className='App-header'>
          <img
            src={'/logo192.png'}
            className='App-logo'
            alt='logo'
            style={{ width: '120px', height: '120px', borderRadius: '60px' }}
          />
          <Typography variant='h4' color='secondary'>
            Ashi Album
          </Typography>
        </header> */}
        <Box sx={{ px: 2, pt: 1 }}>
          <Routes>
            <Route path='/' element={<AlbumsPage />} />
            <Route path='signin' element={<SignInPage />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </Box>
      </Container>
    </div>
  );
}

export default App;
