import { makeAutoObservable, runInAction } from 'mobx';

import MenuItemData from '@ashiteam/base-react-lib/dist/models/MenuItemData/MenuItemData';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import GlobalHelper from '../lib/GlobalHelper';
import { store } from './Store';

export default class NavStore {
  loading = false;
  drawerOpen = false;
  activeMenu: MenuItemData[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setDrawerOpen = (isOpen: boolean) => {
    this.drawerOpen = isOpen;
  };

  toggleDrawer = () => {
    this.drawerOpen = !this.drawerOpen;
  };

  loadActiveMenu = (reload: boolean) => {
    if ((reload || this.activeMenu.length === 0) && !this.loading) {
      this.loading = true;
      try {
        console.log('loadActiveMenu');
        const isLoggedIn = store.userStore.isLoggedIn();
        const isAdmin = store.userStore.isAdmin();
        const name = store.userStore.user?.knownAs;
        const menuItems: MenuItemData[] = [];
        GlobalHelper.menu.forEach((menuItem) => {
          if (
            menuItem.showWhen === 'always' ||
            (menuItem.showWhen === 'loggedIn' && isLoggedIn) ||
            (menuItem.showWhen === 'loggedOut' && !isLoggedIn) ||
            (menuItem.showWhen === 'isAdmin' && isAdmin)
          ) {
            if (isLoggedIn) {
              menuItem.title = menuItem.title.replace(GlobalHelper.userNameMenuTag, name ?? '');
            }
            menuItems.push(menuItem);
          }
        });
        runInAction(() => (this.activeMenu = menuItems));
      } catch (error) {
        console.log('ERROR loadActiveMenu', error);
      } finally {
        runInAction(() => (this.loading = false));
      }
    }
  };

  getTopBarMenuItems = () => {
    const theme = useTheme();
    const isDrawerMode = !useMediaQuery(theme.breakpoints.up('sm'));

    return this.activeMenu.filter((x) => {
      if (x.alwaysInTopBar) {
        return true;
      }
      return !isDrawerMode;
    });
  };
}
